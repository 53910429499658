<template>
  <div class="distribution-again">
    <div class="distribution-body">
      <div class="distribution-title">
        <!-- 选择批准人 -->
        {{ $t("label.selectapprover") }}
      </div>
      <div class="distribution-body">
        <div class="title">
          <!-- 选择批准人 -->
          {{ $t("label.selectapprover") }}
        </div>
        <div class="body">
          <el-form
            :model="formData"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <!-- 下一个审批人 -->
            <el-form-item :label="$t('label.nextapprover')" prop="people">
              <el-input
                v-model="formData.people"
                readonly="readonly"
              ></el-input>
              <!-- 查找 -->
              <span class="search-button" @click="searchPeo"
                >({{ $t("label.conditiontag.lookup") }})</span
              >
            </el-form-item>
            <el-form-item>
              <el-button
                @click="submitForm('ruleForm')"
                :loading="approvalLoading"
              >
                <!-- 发送到下一个审批人 -->
                {{ $t("label.sendtonextapprover") }}
              </el-button>
              <el-button @click="cancel('ruleForm')" :loading="approvalLoading">
                <!-- 取消 -->
                {{ $t("label.cancel") }}
              </el-button>
              <!-- <div @click="submitForm('ruleForm')" class="submit-button">发送到下一个审批人</div>
              <div class="cancel-button" @click="cancel('ruleForm')">取消</div> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.selectapprover')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :checked="false"
          relevant-objid="user"
          relevant-prefix="005"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>

    <div class="select-next-approval">
      <!-- <el-dialog title="选择批准人" -->
      <el-dialog
        :title="$t('label.selectapprover')"
        :visible.sync="isSearch"
        width="700px"
      >
        <select-next-approval
          @changeSearch="changeSearch"
        ></select-next-approval>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { commonApproval } from "./api.js";
import selectNextApproval from "@/views/approval/components/select-next-approval.vue";
import { edictApproval, submitApproval } from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
import SearchTable from "@/components/Form/search-table";
export default {
  name: "distribution-again",
  data() {
    return {
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      showSearchTable: false,
      approvalLoading: false,
      isSearch: false,
      //审批人ID
      fprId: "",
      formData: {
        people: "",
        remarks: "",
      },
      rules: {
        // 值不能为空
        people: [
          {
            required: true,
            message: this.$i18n.t("label.value.notnull"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    selectNextApproval,
    SearchTable,
  },
  mounted() {
    if (this.$route.path == "/approvalCenter/nextApproval") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
  },
  methods: {
    beforeClose() {
      this.$nextTick(() => {
        this.$refs.searchTable.searchConditions = "";
      });
      this.showSearchTable = false;
    },
    //父组件选中批准人
    changeSelect(res) {
      if (Array.isArray(res)) {
        if (res.length === 0) {
          this.fprId = "";
          this.formData.people = "";
        } else {
          res.forEach((item) => {
            this.fprId = item.id;
            this.formData.people = item.name;
          });
        }
      } else {
        this.fprId = res.data.id;
        this.formData.people = res.data.name;
      }
      this.$refs.searchTable.searchConditions = "";
      this.showSearchTable = false;
    },
    //审批请求
    async edictRequest() {
      let option = this.$route.query.option;
      option.fprId = this.fprId;
      let result = await edictApproval(option);
      if (result.returnInfo == "Success!") {
        this.$router.push("/approvalCenter");
      } else if (result.returnInfo == "Manual") {
        this.$router.push({
          path: "/approvalCenter/lastApprovalPeo",
          query: option,
        });
      }
    },
    //获取审批人名称和ID
    deliverName(name, id) {
      this.formData.people = name;
      this.fprId = id;
    },
    //重新分配
    async selectNextAppro() {
      if (this.$route.query.fromSubmit !== "1") {
        let option = {
          comments: this.$route.query.option.comments || "",
          workItemId:
            this.$route.query.option.workItemId || this.$route.query.workItemId,
          actionType: this.$route.query.option.actionType || "Approved",
          fprId: this.fprId,
        };
        let result = await commonApproval(option);
        if (result.returnInfo == "SUCCESS") {
          if (this.$route.query.isHistory === "1") {
            this.$router.go(-1);
          } else if (this.$route.query.isHistory === "2") {
            if (this.$route.query.detailGroupId != "") {
              detailSendCard(this.$route.query.detailGroupId, {
                approval_value: `${localStorage.getItem(
                  "username"
                )}${this.$i18n.t("label.manageAll.sta2")}`,
                title: this.$i18n.t("vue_label_chat_approval_message"),
                type: "APPROVAL_CARD",
                recordId: this.$route.query.recordId,
                changer: localStorage.getItem("username"),
                // body: [
                //   {
                //     fieldLable: "日期",
                //     fieldValue: transformTime(new Date().getTime())
                //   }
                // ]
              });
            }
            this.$router.go(-2);
          } else {
            this.$router.push("/approvalCenter");
            this.$Bus.$emit("cancel-not-loading", true);
          }
        }
      } else {
        submitApproval({
          relatedId: this.$route.query.relatedId,
          fprId: this.fprId,
        }).then((res) => {
          if (!res.result && res.returnCode === "Manual") {
            this.$router.push({
              path: "/approvalCenter/lastApprovalPeo",
              query: {
                relatedId: this.historyID,
                isHistory: "1",
                fromSubmit: "1",
              },
            });
          } else if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: "提交通过",
            });
            if (this.$route.query.isHistory === "1") {
              if (this.$route.query.detailGroupId != "") {
                detailSendCard(this.$route.query.detailGroupId, {
                  approval_value: `${localStorage.getItem(
                    "username"
                  )}${this.$i18n.t("label.submitforapproval")}`,
                  title: this.$i18n.t("vue_label_chat_approval_message"),
                  type: "APPROVAL_CARD",
                  recordId: this.$route.query.recordId,
                  changer: localStorage.getItem("username"),
                  // body: [
                  //   {
                  //     fieldLable: "日期",
                  //     fieldValue: transformTime(new Date().getTime())
                  //   }
                  // ]
                });
              }

              this.$router.go(-1);
            } else if (this.$route.query.isHistory === "2") {
              if (this.$route.query.detailGroupId != "") {
                detailSendCard(this.$route.query.detailGroupId, {
                  approval_value: `${localStorage.getItem(
                    "username"
                  )}${this.$i18n.t("label.manageAll.sta2")}`,
                  title: this.$i18n.t("vue_label_chat_approval_message"),
                  type: "APPROVAL_CARD",
                  recordId: this.$route.query.recordId,
                  changer: localStorage.getItem("username"),
                  // body: [
                  //   {
                  //     fieldLable: "日期",
                  //     fieldValue: transformTime(new Date().getTime())
                  //   }
                  // ]
                });
              }
              this.$router.go(-2);
            }
          }
        });
      }
    },
    // 提交待审批
    async submitApp() {
      let option = {
        relatedId: this.$route.query.relatedId,
        fprId: this.fprId,
      };
      let result = await submitApproval(option);
      if (result.returnCode == "1") {
        this.$router.push({
          path: `/commonObjects/detail/${this.$route.query.relatedId}/DETAIL`,
        });
      } else if (result.returnInfo == "Manual") {
        this.$router.push({
          path: "/approvalCenter/lastApprovalPeo",
          query: option,
        });
      }
    },

    //收起弹出框
    changeSearch(i, id, name) {
      this.isSearch = false;
      this.fprId = id;
      this.formData.people = name;
    },
    //查找批准人
    searchPeo() {
      // this.isSearch = true;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approvalLoading = true;
          this.selectNextAppro();
        } else {
          return false;
        }
      });
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      if (this.$route.query.isHistory === "1") {
        this.$router.go(-1);
      } else if (this.$route.query.isHistory === "2") {
        this.$router.go(-2);
      } else {
        this.$router.push("/approvalCenter");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.distribution-again {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .distribution-body {
    width: 100%;
    .distribution-title {
      height: 49px;
      font-size: 18px;
      background: #f8f8f8;
      font-weight: 500;
      color: #222222;
      line-height: 49px;
      padding-left: 20px;
    }
    .distribution-body {
      .title {
        margin-top: 14px;
        margin-left: 20px;
        font-size: 14px;
        color: #222;
      }
      .body {
        width: 60%;
        margin: 20px auto;
        .el-form {
          .search-button {
            cursor: pointer;
            color: #006dcc;
            font-size: 12px;
            margin-left: 20px;
          }
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            width: 216px;
          }
          .el-form-item__label {
            font-size: 12px;
          }
          .el-input__inner {
            width: 216px;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
            width: 431px;
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
          .submit-button {
            cursor: pointer;
            width: 127px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: left;
            margin-right: 15px;
            font-weight: 400;
          }
          .cancel-button {
            cursor: pointer;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: left;
            font-weight: 400;
          }
        }
      }
    }
  }
  .leftdis {
    margin-left: 230px;
  }
  .el-button {
    padding: 6px 15px;
    color: #006dcc;
    font-size: 12px;
  }
}
</style>
